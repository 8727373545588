import React, { useState, useEffect } from 'react';
import {
    ThemeProvider,
    createTheme,
    CssBaseline,
    useMediaQuery
} from '@mui/material';
import {
    Container,
    Box,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    AppBar,
    Breadcrumbs,
    Toolbar,
    IconButton,
    Link as MuiLink,
    Link
} from '@mui/material';
import { Menu, ChevronDown, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

function TermsAndConditionsPage() {
    const [mode, setMode] = useState('light');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const theme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main: '#6a00ff',
            },
            background: {
                default: mode === 'dark' ? '#120329' : '#f4f4f4',
                paper: mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#ffffff',
            },
            text: {
                primary: mode === 'dark' ? '#ffffff' : '#000000',
                secondary: mode === 'dark'
                    ? 'rgba(255,255,255,0.7)'
                    : 'rgba(0,0,0,0.7)',
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark'
                            ? 'rgba(18, 3, 41, 0.9)'
                            : 'rgba(255, 255, 255, 0.9)',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    }
                }
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark'
                            ? 'rgba(255,255,255,0.05)'
                            : '#ffffff',
                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)',
                        '&:before': {
                            display: 'none',
                        },
                        borderRadius: '12px',
                        marginBottom: '16px',
                        overflow: 'hidden',
                    }
                }
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        padding: '16px 24px',
                        '&.Mui-expanded': {
                            borderBottom: mode === 'dark'
                                ? '1px solid rgba(255,255,255,0.1)'
                                : '1px solid rgba(0,0,0,0.1)',
                        }
                    }
                }
            },
            MuiAccordionDetails: {
                styleOverrides: {
                    root: {
                        padding: '24px',
                    }
                }
            }
        }
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const lastUpdated = "April 1, 2025";
    const Header = () => {
        const [isScrolled, setIsScrolled] = useState(false);

        useEffect(() => {
            const handleScroll = () => {
                setIsScrolled(window.scrollY > 50);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        return (
            <AppBar
                position="sticky"
                sx={{
                    backdropFilter: isScrolled ? 'blur(10px)' : 'none',
                    boxShadow: isScrolled ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                    transition: 'all 0.3s ease'
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 800,
                            background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        StreamNeo
                    </Typography>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 3 }}>
                        <Button
                            sx={{
                                color: theme.palette.text.primary,
                                '&:hover': { color: '#6a00ff' }
                            }}
                            onClick={() => navigate('/')}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{
                                color: '#6a00ff',
                                fontWeight: 'bold',
                                '&:hover': { color: '#ff00ea' }
                            }}
                        >
                            Blog
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => window.location.href = 'https://app.streamneo.com/login'}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 3,
                                py: 1,
                                borderRadius: '32px',
                                fontSize: isMobile ? '0.9rem' : '1.1rem',
                                transition: 'all 0.3s ease',
                                transform: 'scale(1)',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                },
                                '&:active': {
                                    transform: 'scale(0.95)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Box>

                    <IconButton
                        sx={{ display: { md: 'none' }, color: theme.palette.text.primary }}
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    };

    const termsCategories = [
        {
            title: "1. Acceptance of Terms",
            content: `By accessing and using StreamNeo's website, services, or applications, you agree to comply with and be bound by these Terms and Conditions. If you do not agree to these terms, please do not use our services. StreamNeo reserves the right to modify, amend, or update these terms at any time without prior notice. Your continued use of our services following any changes constitutes your acceptance of such changes.`
        },
        {
            title: "2. Description of Service",
            content: `StreamNeo provides a platform for 24/7 live streaming services, including but not limited to video hosting, broadcasting, and content management tools. We reserve the right to modify, suspend, or discontinue any aspect of our services at any time, including the availability of any features, without prior notice or liability.`
        },
        {
            title: "3. User Accounts and Responsibilities",
            content: `When creating an account, you must provide accurate, current, and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify StreamNeo immediately of any unauthorized use of your account or any other breach of security. StreamNeo cannot and will not be liable for any loss or damage arising from your failure to comply with this requirement.`
        },
        {
            title: "4. Content Guidelines",
            content: `You are solely responsible for any content you upload, publish, or display through StreamNeo services. You may not upload, stream, or share content that is illegal, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy, or otherwise objectionable. StreamNeo reserves the right to remove any content that violates these terms or that we find objectionable for any reason, without prior notice.`
        },
        {
            title: "5. Intellectual Property Rights",
            content: `StreamNeo respects intellectual property rights and expects users to do the same. You retain ownership of your content, but grant StreamNeo a worldwide, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate, and distribute your content across our platform and promotional materials. You represent and warrant that you own or have the necessary licenses, rights, consents, and permissions to publish content you submit.`
        },
        {
            title: "6. Payment Terms",
            content: `If you subscribe to a paid service offered by StreamNeo, you agree to pay all fees associated with the service. All fees are exclusive of taxes unless stated otherwise. Payment must be made using approved payment methods. Subscription fees are non-refundable except as required by law or as explicitly stated in our refund policy.`
        },
        {
            title: "7. Privacy Policy",
            content: `Your use of StreamNeo services is also governed by our Privacy Policy, which outlines how we collect, use, and protect your personal information. By using our services, you consent to the collection and use of information as detailed in our Privacy Policy.`
        },
        {
            title: "8. Limitation of Liability",
            content: `StreamNeo shall not be liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, or use, arising out of or in any way connected with the use of our services. In no event shall StreamNeo's total liability to you for all damages exceed the amount paid by you, if any, for accessing our services.`
        },
        {
            title: "9. Termination",
            content: `StreamNeo reserves the right to terminate or suspend your account and access to our services immediately, without prior notice or liability, for any reason, including without limitation if you breach these Terms and Conditions. Upon termination, your right to use the service will immediately cease.`
        },
        {
            title: "10. Governing Law",
            content: `These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction in which StreamNeo operates, without regard to its conflict of law provisions. You agree to submit to the personal and exclusive jurisdiction of the courts located within this jurisdiction.`
        }
    ];

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: '100vh',
                    overflowX: 'hidden',
                    position: 'relative',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary
                }}
            >
                <Header />

                <Container maxWidth="lg" sx={{ mt: 4, mb: 12 }}>
                    <Breadcrumbs
                        separator={<ChevronRight size={14} />}
                        sx={{ my: 2, color: theme.palette.text.secondary }}
                    >
                        <MuiLink
                            component={Link}
                            to="/"
                            color="inherit"
                            sx={{
                                textDecoration: 'none',
                                '&:hover': { color: '#6a00ff' }
                            }}
                        >
                            Home
                        </MuiLink>
                        <Typography color="text.primary">Privacy Policy</Typography>
                    </Breadcrumbs>

                    <Box sx={{ mb: 6 }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 700,
                                mb: 2,
                                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            Terms and Conditions
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: theme.palette.text.secondary,
                                mb: 4
                            }}
                        >
                            Last Updated: {lastUpdated}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: 4,
                            p: { xs: 3, md: 5 },
                            mb: 6,
                            boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                        }}
                    >
                        <Typography variant="body1" paragraph sx={{ mb: 4 }}>
                            Welcome to StreamNeo! These Terms and Conditions govern your use of StreamNeo's services, including our website, applications, and streaming platform. By using our services, you agree to these terms. Please read them carefully.
                        </Typography>

                        <Divider sx={{ my: 4 }} />

                        {termsCategories.map((category, index) => (
                            <Accordion key={index} disableGutters elevation={0}>
                                <AccordionSummary
                                    expandIcon={<ChevronDown size={24} color={theme.palette.primary.main} />}
                                    aria-controls={`panel${index}a-content`}
                                    id={`panel${index}a-header`}
                                >
                                    <Typography variant="h6" fontWeight={600}>{category.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography variant="body1">{category.content}</Typography>
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/contact')}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 4,
                                py: 1.5,
                                borderRadius: '32px',
                                fontSize: '1rem',
                                transition: 'all 0.3s ease',
                                transform: 'scale(1)',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                },
                                '&:active': {
                                    transform: 'scale(0.95)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }
                            }}
                        >
                            Contact Us
                        </Button>
                    </Box>
                </Container>
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default TermsAndConditionsPage;