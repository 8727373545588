import React, { useState, useEffect } from 'react';
import {
    ThemeProvider,
    createTheme,
    CssBaseline,
    useMediaQuery
} from '@mui/material';
import {
    Container,
    Box,
    Typography,
    Button,
    Grid,
    TextField,
    MenuItem,
    Card,
    CardContent,
    AppBar,
    Toolbar,
    IconButton,
    Snackbar,
    Alert,
    Paper
} from '@mui/material';
import { Menu, Mail, Phone, MessageSquare, MapPin } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

function ContactUsPage() {
    const [mode, setMode] = useState('light');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        inquiryType: '',
        message: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const theme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main: '#6a00ff',
            },
            background: {
                default: mode === 'dark' ? '#120329' : '#f4f4f4',
                paper: mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#ffffff',
            },
            text: {
                primary: mode === 'dark' ? '#ffffff' : '#000000',
                secondary: mode === 'dark'
                    ? 'rgba(255,255,255,0.7)'
                    : 'rgba(0,0,0,0.7)',
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark'
                            ? 'rgba(18, 3, 41, 0.9)'
                            : 'rgba(255, 255, 255, 0.9)',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    }
                }
            },
            MuiTextField: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                borderColor: mode === 'dark'
                                    ? 'rgba(255,255,255,0.2)'
                                    : 'rgba(0,0,0,0.2)',
                                borderRadius: '12px',
                            },
                            '&:hover fieldset': {
                                borderColor: '#6a00ff',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#6a00ff',
                            },
                        },
                    }
                }
            }
        }
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Basic validation
        if (!formData.name || !formData.email || !formData.message) {
            setErrorMessage('Please fill in all required fields');
            return;
        }

        // Email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            setErrorMessage('Please enter a valid email address');
            return;
        }

        // Simulate form submission
        console.log('Form submitted:', formData);
        setSubmitted(true);
        setErrorMessage('');

        // Reset form after submission
        setFormData({
            name: '',
            email: '',
            subject: '',
            inquiryType: '',
            message: ''
        });

        // Close success notification after 5 seconds
        setTimeout(() => {
            setSubmitted(false);
        }, 5000);
    };

    const handleCloseSnackbar = () => {
        setSubmitted(false);
        setErrorMessage('');
    };

    const contactMethods = [
        {
            icon: <Mail size={24} color="#6a00ff" />,
            title: "Email Us",
            description: "For general inquiries and support",
            contact: "support@streamneo.com",
            action: "Send Email",
            link: "mailto:support@streamneo.com"
        },
        {
            icon: <Phone size={24} color="#6a00ff" />,
            title: "Call Us",
            description: "Mon-Fri, 9am-5pm (EST)",
            contact: "+1 (555) 123-4567",
            action: "Call Now",
            link: "tel:+15551234567"
        },
        // {
        //     icon: <MessageSquare size={24} color="#6a00ff" />,
        //     title: "Live Chat",
        //     description: "Chat with our support team",
        //     contact: "Available 24/7",
        //     action: "Start Chat",
        //     link: "#" // This would link to your chat system
        // },
        {
            icon: <MapPin size={24} color="#6a00ff" />,
            title: "Visit Us",
            description: "Our headquarters",
            contact: "123 Tech Avenue, San Francisco, CA 94107",
            action: "Get Directions",
            link: "https://maps.google.com"
        }
    ];

    const inquiryTypes = [
        "General Inquiry",
        "Technical Support",
        "Sales Information",
        "Billing Question",
        "Partnership Opportunity",
        "Feature Request",
        "Bug Report",
        "Other"
    ];

    const Header = () => {
        const [isScrolled, setIsScrolled] = useState(false);

        useEffect(() => {
            const handleScroll = () => {
                setIsScrolled(window.scrollY > 50);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        return (
            <AppBar
                position="sticky"
                sx={{
                    backdropFilter: isScrolled ? 'blur(10px)' : 'none',
                    boxShadow: isScrolled ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                    transition: 'all 0.3s ease'
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 800,
                            background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        StreamNeo
                    </Typography>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 3 }}>
                        <Button
                            sx={{
                                color: theme.palette.text.primary,
                                '&:hover': { color: '#6a00ff' }
                            }}
                            onClick={() => navigate('/')}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{
                                color: '#6a00ff',
                                fontWeight: 'bold',
                                '&:hover': { color: '#ff00ea' }
                            }}
                        >
                            Blog
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => window.location.href = 'https://app.streamneo.com/login'}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 3,
                                py: 1,
                                borderRadius: '32px',
                                fontSize: isMobile ? '0.9rem' : '1.1rem',
                                transition: 'all 0.3s ease',
                                transform: 'scale(1)',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                },
                                '&:active': {
                                    transform: 'scale(0.95)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Box>

                    <IconButton
                        sx={{ display: { md: 'none' }, color: theme.palette.text.primary }}
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: '100vh',
                    overflowX: 'hidden',
                    position: 'relative',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary
                }}
            >
                <Header />

                <Container maxWidth="lg" sx={{ mt: 8, mb: 12 }}>
                    <Box sx={{ textAlign: 'center', mb: 8 }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 700,
                                mb: 2,
                                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            Contact Us
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                color: theme.palette.text.secondary,
                                maxWidth: 800,
                                mx: 'auto'
                            }}
                        >
                            Get in touch with our team for support or inquiries
                        </Typography>
                    </Box>

                    <Grid container spacing={4}>
                        {/* Contact Methods */}
                        <Grid item xs={12}>
                            <Grid container spacing={3} sx={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                                {contactMethods.map((method, index) => (
                                    <Grid item xs={12} sm={6} md={3} key={index}>
                                        <Card
                                            sx={{
                                                height: '100%',
                                                borderRadius: 3,
                                                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                                '&:hover': {
                                                    transform: 'translateY(-8px)',
                                                    boxShadow: '0 16px 32px rgba(0,0,0,0.16)',
                                                }
                                            }}
                                        >
                                            <CardContent sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                                <Box
                                                    sx={{
                                                        bgcolor: 'rgba(106, 0, 255, 0.1)',
                                                        borderRadius: '50%',
                                                        p: 2,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        mb: 2
                                                    }}
                                                >
                                                    {method.icon}
                                                </Box>
                                                <Typography variant="h6" fontWeight={600} mb={1}>
                                                    {method.title}
                                                </Typography>
                                                <Typography variant="body2" color="text.secondary" mb={1}>
                                                    {method.description}
                                                </Typography>
                                                <Typography variant="body1" fontWeight={500} mb={2}>
                                                    {method.contact}
                                                </Typography>
                                                <Button
                                                    variant="outlined"
                                                    href={method.link}
                                                    target={method.link.startsWith('http') ? '_blank' : '_self'}
                                                    sx={{
                                                        mt: 'auto',
                                                        color: '#6a00ff',
                                                        borderColor: '#6a00ff',
                                                        borderWidth: '2px',
                                                        '&:hover': {
                                                            borderColor: '#ff00ea',
                                                            color: '#ff00ea',
                                                            borderWidth: '2px',
                                                            bgcolor: 'rgba(106, 0, 255, 0.05)'
                                                        }
                                                    }}
                                                >
                                                    {method.action}
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        {/* Contact Form Section */}
                        {/* <Grid item xs={12} mt={6}>
                            <Paper
                                elevation={0}
                                sx={{
                                    p: { xs: 3, md: 5 },
                                    borderRadius: 4,
                                    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                    bgcolor: theme.palette.background.paper,
                                }}
                            >
                                <Typography variant="h4" fontWeight={700} mb={1}>
                                    Send us a message
                                </Typography>
                                <Typography variant="body1" color="text.secondary" mb={4}>
                                    Fill out the form below and we'll get back to you as soon as possible.
                                </Typography>

                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Full Name"
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Email Address"
                                                name="email"
                                                type="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                fullWidth
                                                label="Subject"
                                                name="subject"
                                                value={formData.subject}
                                                onChange={handleChange}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                select
                                                fullWidth
                                                label="Inquiry Type"
                                                name="inquiryType"
                                                value={formData.inquiryType}
                                                onChange={handleChange}
                                                variant="outlined"
                                            >
                                                {inquiryTypes.map((option) => (
                                                    <MenuItem key={option} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Message"
                                                name="message"
                                                value={formData.message}
                                                onChange={handleChange}
                                                required
                                                multiline
                                                rows={6}
                                                variant="outlined"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{
                                                    bgcolor: '#6a00ff',
                                                    color: 'white',
                                                    px: 4,
                                                    py: 1.5,
                                                    borderRadius: '32px',
                                                    fontSize: '1rem',
                                                    transition: 'all 0.3s ease',
                                                    transform: 'scale(1)',
                                                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                                    '&:hover': {
                                                        bgcolor: '#5a00e6',
                                                        transform: 'scale(1.05)',
                                                        boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                                        background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                                    },
                                                    '&:active': {
                                                        transform: 'scale(0.95)',
                                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                                    }
                                                }}
                                            >
                                                Send Message
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Paper>
                        </Grid> */}

                        {/* FAQ Section */}
                        <Grid item xs={12} mt={6}>
                            <Box sx={{ textAlign: 'center', mb: 4 }}>
                                <Typography variant="h4" fontWeight={700} mb={1}>
                                    Frequently Asked Questions
                                </Typography>
                                <Typography variant="body1" color="text.secondary">
                                    Find quick answers to common questions
                                </Typography>
                            </Box>

                            <Grid container spacing={3}>
                                {[
                                    {
                                        question: "How do I get started with StreamNeo?",
                                        answer: "Simply create an account on our platform, choose a subscription plan that suits your needs, and follow our quick setup guide to start streaming."
                                    },
                                    {
                                        question: "What streaming platforms does StreamNeo support?",
                                        answer: "StreamNeo supports all major streaming platforms including YouTube, Twitch, Facebook Live, Instagram Live, and custom RTMP destinations."
                                    },
                                    {
                                        question: "How secure is my content with StreamNeo?",
                                        answer: "We employ industry-leading security measures including end-to-end encryption, secure cloud storage, and regular security audits to ensure your content is always protected."
                                    },
                                    {
                                        question: "Do you offer refunds?",
                                        answer: "Yes, we offer a 14-day money-back guarantee for all new subscriptions. If you're not satisfied with our service, please contact our support team."
                                    }
                                ].map((faq, index) => (
                                    <Grid item xs={12} md={6} key={index}>
                                        <Paper
                                            elevation={0}
                                            sx={{
                                                p: 3,
                                                borderRadius: 3,
                                                boxShadow: '0 4px 16px rgba(0,0,0,0.08)',
                                                bgcolor: theme.palette.background.paper,
                                                height: '100%'
                                            }}
                                        >
                                            <Typography variant="h6" fontWeight={600} mb={1}>
                                                {faq.question}
                                            </Typography>
                                            <Typography variant="body1" color="text.secondary">
                                                {faq.answer}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                {/* Success and Error notifications */}
                <Snackbar
                    open={submitted}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                        Thank you! Your message has been sent successfully.
                    </Alert>
                </Snackbar>

                <Snackbar
                    open={!!errorMessage}
                    autoHideDuration={5000}
                    onClose={handleCloseSnackbar}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>

                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default ContactUsPage;