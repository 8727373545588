import React, { useState, useEffect } from 'react';
import {
    ThemeProvider,
    createTheme,
    CssBaseline,
    useMediaQuery
} from '@mui/material';
import {
    Container,
    Box,
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    AppBar,
    Toolbar,
    IconButton,
    Avatar,
    Divider
} from '@mui/material';
import { Menu } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

function AboutUsPage() {
    const [mode, setMode] = useState('light');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const theme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main: '#6a00ff',
            },
            background: {
                default: mode === 'dark' ? '#120329' : '#f4f4f4',
                paper: mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#ffffff',
            },
            text: {
                primary: mode === 'dark' ? '#ffffff' : '#000000',
                secondary: mode === 'dark'
                    ? 'rgba(255,255,255,0.7)'
                    : 'rgba(0,0,0,0.7)',
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark'
                            ? 'rgba(18, 3, 41, 0.9)'
                            : 'rgba(255, 255, 255, 0.9)',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    }
                }
            }
        }
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const Header = () => {
        const [isScrolled, setIsScrolled] = useState(false);

        useEffect(() => {
            const handleScroll = () => {
                setIsScrolled(window.scrollY > 50);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        return (
            <AppBar
                position="sticky"
                sx={{
                    backdropFilter: isScrolled ? 'blur(10px)' : 'none',
                    boxShadow: isScrolled ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                    transition: 'all 0.3s ease'
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 800,
                            background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        StreamNeo
                    </Typography>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 3 }}>
                        <Button
                            sx={{
                                color: theme.palette.text.primary,
                                '&:hover': { color: '#6a00ff' }
                            }}
                            onClick={() => navigate('/')}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{
                                color: '#6a00ff',
                                fontWeight: 'bold',
                                '&:hover': { color: '#ff00ea' }
                            }}
                        >
                            Blog
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => window.location.href = 'https://app.streamneo.com/login'}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 3,
                                py: 1,
                                borderRadius: '32px',
                                fontSize: isMobile ? '0.9rem' : '1.1rem',
                                transition: 'all 0.3s ease',
                                transform: 'scale(1)',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                },
                                '&:active': {
                                    transform: 'scale(0.95)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Box>

                    <IconButton
                        sx={{ display: { md: 'none' }, color: theme.palette.text.primary }}
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    };

    const teamMembers = [
        {
            name: "Alex Morgan",
            role: "Founder & CEO",
            bio: "Former streaming engineer with 10+ years of experience in live video infrastructure. Founded StreamNeo to make 24/7 streaming accessible to everyone.",
            avatar: "/images/team/alex-morgan.jpg"
        },
        {
            name: "Sarah Chen",
            role: "CTO",
            bio: "Ex-Google engineer specialized in video optimization algorithms. Sarah leads our technical team to build reliable and scalable streaming solutions.",
            avatar: "/images/team/sarah-chen.jpg"
        },
        {
            name: "Marcus Johnson",
            role: "Head of Product",
            bio: "Product visionary with experience at Twitch and YouTube. Marcus ensures StreamNeo meets the real needs of content creators.",
            avatar: "/images/team/marcus-johnson.jpg"
        },
        {
            name: "Priya Sharma",
            role: "Customer Success Lead",
            bio: "With a background in community management, Priya ensures our users get the most out of StreamNeo with dedicated support.",
            avatar: "/images/team/priya-sharma.jpg"
        }
    ];

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: '100vh',
                    overflowX: 'hidden',
                    position: 'relative',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary
                }}
            >
                <Header />

                <Container maxWidth="lg" sx={{ mt: 8, mb: 12 }}>
                    <Box sx={{ textAlign: 'center', mb: 8 }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 700,
                                mb: 2,
                                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            About StreamNeo
                        </Typography>
                        <Typography
                            variant="h5"
                            sx={{
                                color: theme.palette.text.secondary,
                                maxWidth: 800,
                                mx: 'auto',
                                mb: 6
                            }}
                        >
                            Empowering creators with the future of 24/7 live streaming
                        </Typography>
                    </Box>

                    <Grid container spacing={6} alignItems="center" sx={{ mb: 12 }}>
                        <Grid item xs={12} md={6}>
                            <Box
                                component="img"
                                src="/images/about/mission.jpg"
                                alt="StreamNeo Mission"
                                sx={{
                                    width: '100%',
                                    borderRadius: 4,
                                    boxShadow: '0 16px 40px rgba(0,0,0,0.2)',
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: 700,
                                    mb: 3,
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent'
                                }}
                            >
                                Our Mission
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 3, fontSize: '1.1rem' }}>
                                At StreamNeo, we're on a mission to democratize 24/7 live streaming for content creators worldwide. We believe every creator should have access to powerful, reliable streaming technology without technical barriers or prohibitive costs.
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 3, fontSize: '1.1rem' }}>
                                Founded in 2022, StreamNeo was born from the frustration of seeing talented creators struggle with complex streaming setups and unreliable solutions. We've built a platform that makes continuous live streaming simple, affordable, and accessible to everyone.
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                                Today, thousands of creators use StreamNeo to maintain an always-on presence for their communities, growing their audience and engagement while they sleep.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box sx={{ mb: 12 }}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 700,
                                mb: 6,
                                textAlign: 'center',
                                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            What Sets Us Apart
                        </Typography>

                        <Grid container spacing={4}>
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        borderRadius: 4,
                                        p: 2,
                                        boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                        background: mode === 'dark'
                                            ? 'linear-gradient(135deg, rgba(106, 0, 255, 0.2), rgba(255, 0, 234, 0.2))'
                                            : theme.palette.background.paper
                                    }}
                                >
                                    <CardContent sx={{ p: 3 }}>
                                        <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>
                                            Reliability First
                                        </Typography>
                                        <Typography variant="body1">
                                            Our infrastructure is built for 99.99% uptime, with automatic failovers and redundant systems across multiple geographic regions. Your stream stays online, even when you're not.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        borderRadius: 4,
                                        p: 2,
                                        boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                        background: mode === 'dark'
                                            ? 'linear-gradient(135deg, rgba(106, 0, 255, 0.2), rgba(255, 0, 234, 0.2))'
                                            : theme.palette.background.paper
                                    }}
                                >
                                    <CardContent sx={{ p: 3 }}>
                                        <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>
                                            No Technical Hassle
                                        </Typography>
                                        <Typography variant="body1">
                                            Our intuitive interface lets you upload content, schedule broadcasts, and monitor performance without needing any technical expertise or cumbersome setups.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        borderRadius: 4,
                                        p: 2,
                                        boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                        background: mode === 'dark'
                                            ? 'linear-gradient(135deg, rgba(106, 0, 255, 0.2), rgba(255, 0, 234, 0.2))'
                                            : theme.palette.background.paper
                                    }}
                                >
                                    <CardContent sx={{ p: 3 }}>
                                        <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>
                                            Growth-Focused
                                        </Typography>
                                        <Typography variant="body1">
                                            We don't just keep your stream running—our analytics and audience engagement tools help you understand your viewers and grow your community around the clock.
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Box>

                    <Box sx={{ mb: 12 }}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 700,
                                mb: 6,
                                textAlign: 'center',
                                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            Meet Our Team
                        </Typography>

                        <Grid container spacing={4}>
                            {teamMembers.map((member, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    <Card
                                        sx={{
                                            height: '100%',
                                            borderRadius: 4,
                                            overflow: 'hidden',
                                            boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                            transition: 'transform 0.3s ease',
                                            '&:hover': {
                                                transform: 'translateY(-8px)'
                                            }
                                        }}
                                    >
                                        <Box sx={{ position: 'relative', pt: '100%' }}>
                                            <Avatar
                                                src={member.avatar}
                                                alt={member.name}
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    width: '100%',
                                                    height: '100%',
                                                    bgcolor: 'rgba(106, 0, 255, 0.1)',
                                                    fontSize: '3rem'
                                                }}
                                            >
                                                {member.name.charAt(0)}
                                            </Avatar>
                                        </Box>
                                        <CardContent sx={{ p: 3 }}>
                                            <Typography variant="h6" sx={{ fontWeight: 700, mb: 1 }}>
                                                {member.name}
                                            </Typography>
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    mb: 2,
                                                    color: '#6a00ff',
                                                    fontWeight: 600
                                                }}
                                            >
                                                {member.role}
                                            </Typography>
                                            <Typography variant="body2">
                                                {member.bio}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>

                    <Box sx={{ textAlign: 'center', mb: 8, mt: 12 }}>
                        <Typography
                            variant="h3"
                            sx={{
                                fontWeight: 700,
                                mb: 4,
                                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            Join Thousands of Streamers
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{
                                color: theme.palette.text.secondary,
                                maxWidth: 700,
                                mx: 'auto',
                                mb: 4
                            }}
                        >
                            Ready to take your streaming to the next level with 24/7 live content?
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => window.location.href = 'https://app.streamneo.com/signup'}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 6,
                                py: 1.5,
                                borderRadius: '32px',
                                fontSize: '1.2rem',
                                transition: 'all 0.3s ease',
                                transform: 'scale(1)',
                                boxShadow: '0 4px 12px rgba(106, 0, 255, 0.4)',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 8px 20px rgba(106, 0, 255, 0.6)',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                },
                                '&:active': {
                                    transform: 'scale(0.98)',
                                    boxShadow: '0 2px 8px rgba(106, 0, 255, 0.4)',
                                }
                            }}
                        >
                            Get Started Free
                        </Button>
                    </Box>
                </Container>
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default AboutUsPage;