import React, { useState, useEffect } from 'react';
import {
    ThemeProvider,
    createTheme,
    CssBaseline,
    useMediaQuery
} from '@mui/material';
import {
    Container,
    Box,
    Typography,
    Button,
    Paper,
    AppBar,
    Toolbar,
    IconButton,
    Breadcrumbs,
    Link as MuiLink
} from '@mui/material';
import { Menu, ChevronRight } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import Footer from './Footer';

function PrivacyPolicyPage() {
    const [mode, setMode] = useState('light');
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const theme = createTheme({
        palette: {
            mode: mode,
            primary: {
                main: '#6a00ff',
            },
            background: {
                default: mode === 'dark' ? '#120329' : '#f4f4f4',
                paper: mode === 'dark' ? 'rgba(255,255,255,0.05)' : '#ffffff',
            },
            text: {
                primary: mode === 'dark' ? '#ffffff' : '#000000',
                secondary: mode === 'dark'
                    ? 'rgba(255,255,255,0.7)'
                    : 'rgba(0,0,0,0.7)',
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
        },
        components: {
            MuiAppBar: {
                styleOverrides: {
                    root: {
                        backgroundColor: mode === 'dark'
                            ? 'rgba(18, 3, 41, 0.9)'
                            : 'rgba(255, 255, 255, 0.9)',
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                    }
                }
            }
        }
    });

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();

    const Header = () => {
        const [isScrolled, setIsScrolled] = useState(false);

        useEffect(() => {
            const handleScroll = () => {
                setIsScrolled(window.scrollY > 50);
            };

            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        return (
            <AppBar
                position="sticky"
                sx={{
                    backdropFilter: isScrolled ? 'blur(10px)' : 'none',
                    boxShadow: isScrolled ? '0 4px 6px rgba(0,0,0,0.1)' : 'none',
                    transition: 'all 0.3s ease'
                }}
            >
                <Toolbar sx={{ justifyContent: 'space-between', py: 1 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 800,
                            background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        StreamNeo
                    </Typography>

                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center', gap: 3 }}>
                        <Button
                            sx={{
                                color: theme.palette.text.primary,
                                '&:hover': { color: '#6a00ff' }
                            }}
                            onClick={() => navigate('/')}
                        >
                            Home
                        </Button>
                        <Button
                            sx={{
                                color: '#6a00ff',
                                fontWeight: 'bold',
                                '&:hover': { color: '#ff00ea' }
                            }}
                        >
                            Blog
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => window.location.href = 'https://app.streamneo.com/login'}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 3,
                                py: 1,
                                borderRadius: '32px',
                                fontSize: isMobile ? '0.9rem' : '1.1rem',
                                transition: 'all 0.3s ease',
                                transform: 'scale(1)',
                                boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    transform: 'scale(1.05)',
                                    boxShadow: '0 6px 8px rgba(0,0,0,0.2)',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                },
                                '&:active': {
                                    transform: 'scale(0.95)',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                }
                            }}
                        >
                            Login
                        </Button>
                    </Box>

                    <IconButton
                        sx={{ display: { md: 'none' }, color: theme.palette.text.primary }}
                        onClick={() => setIsMobileMenuOpen(true)}
                    >
                        <Menu />
                    </IconButton>
                </Toolbar>
            </AppBar>
        );
    };

    const sections = [
        {
            id: "overview",
            title: "Overview",
            content: "This Privacy Policy explains how StreamNeo ('we', 'us', or 'our') collects, uses, and discloses your information when you use our streaming platform services. By using StreamNeo, you agree to the collection and use of information in accordance with this policy. We are committed to protecting your privacy and personal data."
        },
        {
            id: "information-collection",
            title: "Information We Collect",
            content: "We collect several types of information for various purposes to provide and improve our service to you:\n\n• Personal Information: When you sign up for StreamNeo, we collect information such as your name, email address, billing information, and profile details.\n\n• Usage Data: We collect information on how you access and use our services, including your IP address, browser type, pages visited, time spent on those pages, and other diagnostic data.\n\n• Stream Content: We store content you upload for streaming, including videos, images, and audio files.\n\n• Payment Information: When you make purchases, we collect payment details necessary to process your transaction (though we do not store complete credit card information)."
        },
        {
            id: "information-use",
            title: "How We Use Your Information",
            content: "We use the collected information for various purposes, including to:\n\n• Provide and maintain our service\n• Notify you about changes to our service\n• Allow you to participate in interactive features\n• Provide customer support\n• Monitor usage of our service\n• Detect, prevent, and address technical issues\n• Provide personalized content and recommendations\n• Process payments and prevent fraud"
        },
        {
            id: "data-sharing",
            title: "Sharing Your Information",
            content: "We may share your information with:\n\n• Service Providers: Third parties who provide services on our behalf, such as payment processing, analytics, and customer support.\n\n• Business Partners: With your consent, we may share your information with our business partners to offer certain products, services, or promotions.\n\n• Legal Requirements: When required by law or to protect our rights, we may disclose your information to comply with a legal obligation or in response to valid requests by public authorities.\n\n• Business Transfers: In connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business."
        },
        {
            id: "data-security",
            title: "Data Security",
            content: "The security of your data is important to us, but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. We implement various security measures to maintain the safety of your personal information, including encryption, access controls, and regular security assessments."
        },
        {
            id: "international-transfers",
            title: "International Data Transfers",
            content: "Your information may be transferred to — and maintained on — computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. If you are located outside the United States and choose to provide information to us, please note that we transfer the data to the United States and process it there."
        },
        {
            id: "data-retention",
            title: "Data Retention",
            content: "We will retain your personal information only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies."
        },
        {
            id: "your-rights",
            title: "Your Rights",
            content: "Depending on your location, you may have certain rights regarding your personal information, including:\n\n• Access: You can request copies of your personal information we hold.\n\n• Rectification: You can ask us to correct inaccurate information or complete incomplete information.\n\n• Erasure: In certain circumstances, you can ask us to delete your personal information.\n\n• Restriction: You can ask us to suspend processing of your personal information.\n\n• Data Portability: You may have the right to obtain and reuse your personal information across different services.\n\n• Objection: You may have the right to object to our processing of your personal information."
        },
        {
            id: "children-privacy",
            title: "Children's Privacy",
            content: "Our services are not intended for use by children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we can take necessary actions."
        },
        {
            id: "changes-policy",
            title: "Changes to This Privacy Policy",
            content: "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the 'Last Updated' date. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page."
        },
        {
            id: "contact-us",
            title: "Contact Us",
            content: "If you have any questions about this Privacy Policy, please contact us at privacy@streamneo.com or through the contact form on our website."
        }
    ];

    const lastUpdated = "April 1, 2025";

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: '100vh',
                    overflowX: 'hidden',
                    position: 'relative',
                    backgroundColor: theme.palette.background.default,
                    color: theme.palette.text.primary
                }}
            >
                <Header />

                <Container maxWidth="lg" sx={{ mt: 4, mb: 12 }}>
                    <Breadcrumbs
                        separator={<ChevronRight size={14} />}
                        sx={{ my: 2, color: theme.palette.text.secondary }}
                    >
                        <MuiLink
                            component={Link}
                            to="/"
                            color="inherit"
                            sx={{
                                textDecoration: 'none',
                                '&:hover': { color: '#6a00ff' }
                            }}
                        >
                            Home
                        </MuiLink>
                        <Typography color="text.primary">Privacy Policy</Typography>
                    </Breadcrumbs>

                    <Box sx={{ mb: 6 }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 700,
                                mb: 2,
                                background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent'
                            }}
                        >
                            Privacy Policy
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            sx={{
                                color: theme.palette.text.secondary,
                                mb: 4
                            }}
                        >
                            Last Updated: {lastUpdated}
                        </Typography>
                    </Box>

                    <Paper
                        sx={{
                            p: { xs: 3, md: 6 },
                            borderRadius: 4,
                            boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
                            mb: 8
                        }}
                    >
                        <Box sx={{ mb: 6 }}>
                            <Typography variant="body1" sx={{ mb: 4, fontSize: '1.1rem' }}>
                                At StreamNeo, we take your privacy seriously. This Privacy Policy is designed to help you understand how we collect, use, and safeguard your personal information.
                            </Typography>
                            <Typography variant="body1" sx={{ fontSize: '1.1rem' }}>
                                Please read this Privacy Policy carefully. By accessing or using our services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Privacy Policy.
                            </Typography>
                        </Box>

                        {sections.map((section) => (
                            <Box key={section.id} id={section.id} sx={{ mb: 6 }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontWeight: 700,
                                        mb: 2,
                                        color: '#6a00ff'
                                    }}
                                >
                                    {section.title}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        whiteSpace: 'pre-line',
                                        fontSize: '1.05rem'
                                    }}
                                >
                                    {section.content}
                                </Typography>
                            </Box>
                        ))}
                    </Paper>

                    <Box sx={{ textAlign: 'center', mb: 4 }}>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            For more information about our practices or if you have questions, please contact us.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => navigate('/contact')}
                            sx={{
                                bgcolor: '#6a00ff',
                                color: 'white',
                                px: 4,
                                py: 1,
                                borderRadius: '32px',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                    bgcolor: '#5a00e6',
                                    background: 'linear-gradient(90deg, #6a00ff, #ff00ea)',
                                }
                            }}
                        >
                            Contact Us
                        </Button>
                    </Box>
                </Container>
                <Footer />
            </Box>
        </ThemeProvider>
    );
}

export default PrivacyPolicyPage;